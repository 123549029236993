@font-face {
	font-family: InterVariable;
	font-style: normal;
	font-weight: 100 900;
	font-display: swap;
	src: url("/assets/melon/css/fonts/inter/InterVariable.woff2") format("woff2");
  }
  @font-face {
	font-family: InterVariable;
	font-style: italic;
	font-weight: 100 900;
	font-display: swap;
	src: url("/assets/melon/css/fonts/inter/InterVariable-Italic.woff2") format("woff2");
  }
  /* static fonts */
  @font-face { font-family: "Inter"; font-style: normal; font-weight: 100; font-display: swap; src: url("/assets/melon/css/fonts/inter/Inter-Thin.woff2") format("woff2"); }
  @font-face { font-family: "Inter"; font-style: italic; font-weight: 100; font-display: swap; src: url("/assets/melon/css/fonts/inter/Inter-ThinItalic.woff2") format("woff2"); }
  @font-face { font-family: "Inter"; font-style: normal; font-weight: 200; font-display: swap; src: url("/assets/melon/css/fonts/inter/Inter-ExtraLight.woff2") format("woff2"); }
  @font-face { font-family: "Inter"; font-style: italic; font-weight: 200; font-display: swap; src: url("/assets/melon/css/fonts/inter/Inter-ExtraLightItalic.woff2") format("woff2"); }
  @font-face { font-family: "Inter"; font-style: normal; font-weight: 300; font-display: swap; src: url("/assets/melon/css/fonts/inter/Inter-Light.woff2") format("woff2"); }
  @font-face { font-family: "Inter"; font-style: italic; font-weight: 300; font-display: swap; src: url("/assets/melon/css/fonts/inter/Inter-LightItalic.woff2") format("woff2"); }
  @font-face { font-family: "Inter"; font-style: normal; font-weight: 400; font-display: swap; src: url("/assets/melon/css/fonts/inter/Inter-Regular.woff2") format("woff2"); }
  @font-face { font-family: "Inter"; font-style: italic; font-weight: 400; font-display: swap; src: url("/assets/melon/css/fonts/inter/Inter-Italic.woff2") format("woff2"); }
  @font-face { font-family: "Inter"; font-style: normal; font-weight: 500; font-display: swap; src: url("/assets/melon/css/fonts/inter/Inter-Medium.woff2") format("woff2"); }
  @font-face { font-family: "Inter"; font-style: italic; font-weight: 500; font-display: swap; src: url("/assets/melon/css/fonts/inter/Inter-MediumItalic.woff2") format("woff2"); }
  @font-face { font-family: "Inter"; font-style: normal; font-weight: 600; font-display: swap; src: url("/assets/melon/css/fonts/inter/Inter-SemiBold.woff2") format("woff2"); }
  @font-face { font-family: "Inter"; font-style: italic; font-weight: 600; font-display: swap; src: url("/assets/melon/css/fonts/inter/Inter-SemiBoldItalic.woff2") format("woff2"); }
  @font-face { font-family: "Inter"; font-style: normal; font-weight: 700; font-display: swap; src: url("/assets/melon/css/fonts/inter/Inter-Bold.woff2") format("woff2"); }
  @font-face { font-family: "Inter"; font-style: italic; font-weight: 700; font-display: swap; src: url("/assets/melon/css/fonts/inter/Inter-BoldItalic.woff2") format("woff2"); }
  @font-face { font-family: "Inter"; font-style: normal; font-weight: 800; font-display: swap; src: url("/assets/melon/css/fonts/inter/Inter-ExtraBold.woff2") format("woff2"); }
  @font-face { font-family: "Inter"; font-style: italic; font-weight: 800; font-display: swap; src: url("/assets/melon/css/fonts/inter/Inter-ExtraBoldItalic.woff2") format("woff2"); }
  @font-face { font-family: "Inter"; font-style: normal; font-weight: 900; font-display: swap; src: url("/assets/melon/css/fonts/inter/Inter-Black.woff2") format("woff2"); }
  @font-face { font-family: "Inter"; font-style: italic; font-weight: 900; font-display: swap; src: url("/assets/melon/css/fonts/inter/Inter-BlackItalic.woff2") format("woff2"); }
